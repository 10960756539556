import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FranchiseService } from "../../@core/backend/receptionist/services/franchise-service";

@Component({
  selector: "r247-nav-bar",
  templateUrl: "./nav-bar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class R247NavBarComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private franchiseService: FranchiseService
  ) {}

  @Input() companyName = "";
  @Input() navItems = [];
  @Input() accountLink = "#";
  @Input() accountButtonText = "";
  @Input() switchLink: any = {};
  @Input() showSwitchLink = false;
  @Input() showAccountLink = true;

  vendorName = "";
  logoImage: SafeResourceUrl;
  vendorWebsite = "#";
  @Input() isAuthenticated = false;

  isNavCollapsed = false;

  authenticationButtonText = "";
  authenticationLink = "#";

  ngOnInit(): void {
    this.franchiseService.get().subscribe((config) => {
      this.vendorName = config.name;
      this.vendorWebsite = `${window.location.protocol}`;
      this.logoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
        config.logo
      );
      this.cd.detectChanges();
    });

    if (this.isAuthenticated) {
      this.authenticationButtonText = "Log Out";
      this.authenticationLink = "auth/logout";
    } else {
      this.authenticationButtonText = "Log In";
      this.authenticationLink = "auth/login";
    }
  }
}
