<div>
    <r247-nav-bar [showAccountLink]="false">
    </r247-nav-bar>
    <div class="container-fluid">
        <div class="row" style="margin-top: -0.5rem;">
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="footer text-white text-center px-1 py-2">
        <div class="d-flex justify-content-center">
            <div class="mx-4"><a routerLink="/terms" class="text-white">Terms And Conditions</a></div>
            <div class="mx-4"><a routerLink="/privacy" class="text-white">Privacy Policy</a></div>
        </div>
    </div>
</div>