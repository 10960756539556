import { Component, OnInit } from "@angular/core";
import { FranchiseService } from "../../../@core/backend/receptionist/services/franchise-service";

@Component({
  selector: "r247-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
})
export class R247PrivacyPolicyScreenComponent implements OnInit {
  constructor(private franchiseService: FranchiseService) {}

  resellerName = "";
  resellerReceptionistWebsite = "";

  ngOnInit() {
    this.franchiseService.get().subscribe((x) => {
      this.resellerName = x.name;
    });

    this.resellerReceptionistWebsite = window.location.hostname;
  }
}
