<div class="container my-4">
    <h2 class="text-center">Privacy Policy</h2>
        <section class="mt-4">
            <h1>Introduction</h1>
            <p>Welcome to <strong>{{resellerReceptionistWebsite}}</strong> (the "Website"). This website is operated by <strong>{{resellerName}}</strong>, an authorised reseller of <a href="https://www.totally247.co.uk" target="_blank">Totally247 Ltd</a>. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This privacy policy outlines how we collect, use, and protect your information.</p>
        </section>
        <section class="mt-4">
            <h1>Information We Collect</h1>
            <h3>Personal Information</h3>
            <ul>
                <li>Name</li>
                <li>Email Address</li>
                <li>Phone Number</li>
                <li>Company Name</li>
                <li>Address</li>
            </ul>
            <h3>Usage Data</h3>
            <ul>
                <li>IP Address</li>
                <li>Browser Type and Version</li>
                <li>Pages Visited</li>
                <li>Time and Date of Visit</li>
                <li>Time Spent on Pages</li>
            </ul>
        </section>
        <section class="mt-4">
            <h1>How We Use Your Information</h1>
            <ul>
                <li>To provide and maintain our services</li>
                <li>To notify you about changes to our services</li>
                <li>To allow you to participate in interactive features</li>
                <li>To provide customer support</li>
                <li>To gather analytical insights for service improvement</li>
                <li>To monitor service usage</li>
                <li>To detect, prevent, and address technical issues</li>
                <li>To send marketing and promotional materials (if opted-in)</li>
            </ul>
        </section>
        <section class="mt-4">
            <h1>Cookies and Tracking Technologies</h1>
            <p>We use cookies and similar tracking technologies to monitor website activity. You can set your browser to refuse cookies; however, this may affect website functionality.</p>
        </section>
        <section class="mt-4">
            <h3>Legal Basis for Processing Personal Data</h3>
            <ul>
                <li>Contractual necessity</li>
                <li>Consent</li>
                <li>Legitimate interests</li>
                <li>Legal compliance</li>
                <li>Payment processing</li>
            </ul>
        </section>
        <section class="mt-4">
            <h1>Data Retention</h1>
            <p>We retain your personal information only as long as necessary to fulfill legal obligations, resolve disputes, and enforce policies.</p>
        </section>
        <section class="mt-4">
            <h1>Data Transfer</h1>
            <p>Your personal data may be stored or processed in locations outside of your jurisdiction, where data protection laws may differ.</p>
        </section>
        <section class="mt-4">
            <h1>Data Security</h1>
            <p>We employ commercially acceptable means to protect your data, but absolute security cannot be guaranteed.</p>
        </section>
        <section class="mt-4">
            <h1>Your Data Protection Rights</h1>
            <ul>
                <li><strong>Right to Access:</strong> Request copies of your personal data.</li>
                <li><strong>Right to Rectification:</strong> Request correction of inaccurate or incomplete data.</li>
                <li><strong>Right to Erasure:</strong> Request deletion of your personal data under certain conditions.</li>
                <li><strong>Right to Restrict Processing:</strong> Request limitation on data processing.</li>
                <li><strong>Right to Object:</strong> Object to data processing under certain conditions.</li>
                <li><strong>Right to Data Portability:</strong> Request data transfer to another entity or yourself.</li>
            </ul>
        </section>
        <section class="mt-4">
            <h1>Changes to This Privacy Policy</h1>
            <p>We may update this privacy policy periodically. Updates will be posted on this page.</p>
        </section>
</div>