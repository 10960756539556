import { Component, OnInit } from "@angular/core";
import { FranchiseService } from "../../../@core/backend/receptionist/services/franchise-service";

@Component({
  selector: "r247-terms",
  templateUrl: "./terms.component.html",
})
export class R247TermsScreenComponent implements OnInit {
  constructor(private franchiseService: FranchiseService) {}

  resellerName = "";

  ngOnInit() {
    this.franchiseService.get().subscribe((x) => {
      this.resellerName = x.name;
    });
  }
}
