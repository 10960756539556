<div class="container my-4">
    <h2 class="text-center">Website Terms and Conditions</h2>
    <section class="mt-4">
        <h1>1. Introduction</h1>
        <p>1.1 These terms and conditions (the "Terms") govern your use of our website (the "Website") and the services provided by <strong>{{resellerName}}</strong>, an authorised reseller of <a href="https://www.totally247.co.uk" target="_blank">Totally247 Ltd</a>.</p>
        <p>1.2 By using the Website, you accept these Terms in full. If you disagree with these Terms or any part of these Terms, you must not use our Website.</p>
        <p>1.3 Our Website may use cookies. By using our Website and agreeing to these Terms, you consent to our use of cookies in accordance with our <a routerLink="/privacy">privacy policy</a>.</p>
    </section>
    <section class="mt-4">
        <h1>2. Definitions</h1>
        <ul>
            <li>2.1 <strong>"Services"</strong> refers to any service provided by the Company through the Website as an authorised reseller of Totally247 Ltd.</li>
            <li>2.2 <strong>"User"</strong> refers to any person or entity using the Website or Services.</li>
        </ul>
    </section>   
    <section class="mt-4">
        <h1>3. Use of the Website</h1>
        <ul>
            <li>3.1 You must be at least 18 years of age to use our Website.</li>
            <li>3.2 You must ensure that any information you provide to us through the Website is accurate, complete, and current.</li>
            <li>3.3 You must not use our Website in any way that causes damage or impairment to its availability or accessibility.</li>
        </ul>
    </section>
    <section class="mt-4">
        <h1>4. Services</h1>
        <ul>
            <li>4.1 {{resellerName}} will provide the Services with reasonable skill and care.</li>
            <li>4.2 We reserve the right to refuse to provide Services to anyone for any reason at any time.</li>
        </ul>
    </section>
    <section class="mt-4">
        <h1>5. Fees and Payment</h1>
        <ul>
            <li>5.1 The fees for our Services are as stated on the Website and are subject to change.</li>
            <li>5.2 Payments must be made via the methods specified on the Website.</li>
        </ul>
    </section>
    <section class="mt-4">
        <h1>6. Cancellation and Refunds</h1>
        <p>6.1 You may cancel your order for Services in accordance with our cancellation policy, as detailed on the Website.</p>
    </section>
    <section class="mt-4">
        <h1>7. Intellectual Property</h1>
        <p>7.1 All content on the Website, including text, graphics, logos, and software, is the property of {{resellerName}} or its licensors and is protected by copyright and intellectual property laws.</p>
    </section>
    <section class="mt-4">
        <h1>8. Limitations of Liability</h1>
        <ul>
            <li>8.1 We do not exclude liability for death or personal injury resulting from our negligence.</li>
            <li>8.2 We will not be liable for any losses arising out of events beyond our reasonable control.</li>
            <li>8.3 We will not be liable for business losses, including loss of profits, income, revenue, or goodwill.</li>
        </ul>
    </section>
    <section class="mt-4">
        <h1>9. Privacy</h1>
        <p>9.1 Our use of your personal information is governed by our <a routerLink="privacy">privacy policy</a>.</p>
    </section>
    <section class="mt-4">
        <h1>10. Changes to the Terms</h1>
        <p>10.1 We may revise these Terms from time to time. Please check this page regularly for updates.</p>
    </section>
    <section class="mt-4">
        <h1>11. Governing Law and Jurisdiction</h1>
        <p>11.1 These Terms are governed by English law. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
    </section>
</div>