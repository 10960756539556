import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { R247MarketingScreenComponent } from "./screens/marketing/marketing-screen.component";
import { R247MarketingComponent } from "./marketing.component";
import { R247TermsScreenComponent } from "./screens/legal/terms.component";
import { R247PrivacyPolicyScreenComponent } from "./screens/legal/privacy-policy.component";

const routes: Routes = [
  {
    path: "",
    component: R247MarketingComponent,
    children: [
      {
        path: "",
        component: R247MarketingScreenComponent,
      },
      {
        path: "terms",
        component: R247TermsScreenComponent,
      },
      {
        path: "privacy",
        component: R247PrivacyPolicyScreenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingRoutingModule {}
